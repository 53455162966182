import { Zeus } from './../generated.d';
import { MobileScreens } from 'app-modules/mobile-screens/generated';
import * as Constants from './../constants';

export class StaticInventoryUtils {

  public getNumberOfLocation(locations: Zeus.Web.Model.Location[], firstRow: string, lastRow: string, firstBay: string = null, lastBay: string = null, onlyLocked: boolean = false): number {
    let nbOfLocations = 0;
    if (locations.length > 0) {
      var filteredLocations = locations
        .filter((x: MobileScreens.Model.StaticRackLocation) => x.row >= firstRow)
        .filter((x: MobileScreens.Model.StaticRackLocation) => x.row <= lastRow)
      if (firstBay != null && lastBay != null) {
        filteredLocations = filteredLocations
          .filter((x: MobileScreens.Model.StaticRackLocation) => x.bay >= firstBay)
          .filter((x: MobileScreens.Model.StaticRackLocation) => x.bay <= lastBay)
      }
      if (onlyLocked) {
        filteredLocations = filteredLocations
          .filter((x: MobileScreens.Model.StaticRackLocation) => x.locationBlockId == Constants.InventoryBlockId.Locked)
      }
      nbOfLocations = filteredLocations.length;
    }
    return nbOfLocations;
  }
}
