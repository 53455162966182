import { Router } from 'aurelia-router';
import { autoinject, bindable, computedFrom, customElement } from "aurelia-framework";
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, ServiceBase, Various } from "digiwall-lib";
import { Conveyor } from "../generated";
import * as ConvConstants from "../constants";
import * as Constants from "../../../constants";
import { ValidationRules } from 'aurelia-validation';

@autoinject
@customElement('conveyor-detail-view')
export class ConveyorDetailView extends EntityDetailViewModelBase<Conveyor.Model.ConveyorConfiguration> {
  public ressourceName: string = Constants.EntityTypeNames.Storage;

  @bindable private conveyorId: number;
  @bindable private onCancel = () => { };
  @bindable private onSaved: (args: { conveyor: Conveyor.Model.ConveyorConfiguration }) => Promise<void>;

  private exitStationService: ServiceBase<Conveyor.Model.ExitStation>;
  private autoLaunchStationService: ServiceBase<Conveyor.Model.AutoLaunchStation>;
  private stationDescriptionService: ServiceBase<Conveyor.Model.StationDescription>;

  @computedFrom('editingMode', 'entity.name', '_langWatcher')
  get documentTitle(): string {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr('menu.addnew') + ' ' + this.i18n.tr("conveyorconfiguration.conveyorconfiguration");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name;
    }
  }

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Conveyor.Model.ConveyorConfiguration>(ConvConstants.EntityTypeNames.ConveyorConfiguration));
    this.exitStationService = new ServiceBase<Conveyor.Model.ExitStation>(ConvConstants.EntityTypeNames.ExitStation);
    this.autoLaunchStationService = new ServiceBase<Conveyor.Model.AutoLaunchStation>(ConvConstants.EntityTypeNames.AutoLaunchStation);
    this.stationDescriptionService = new ServiceBase<Conveyor.Model.StationDescription>(ConvConstants.EntityTypeNames.StationDescription);
  }

  public async attached() {
    if (this.conveyorId == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({
        completeInputBinNumber: null,
        partialInputBinNumber: null,
        pickingBinNumber: null
      });
    } else if (this.conveyorId > 0) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(this.conveyorId, 'autoLaunchStations', 'exitStations', 'stationDescriptions');
    } else {
      throw `Incorrect id ${this.conveyorId}`;
    }

    ValidationRules
      .ensure("exitStations")
      .minItems(1)
      .ensure("nbMinEmptyBinToAutoLaunch")
      .satisfies((val, obj) => val <= this.entity.nbMaxEmptyBinToAutoLaunch)
      .when(x => this.entity.nbMaxEmptyBinToAutoLaunch != null)
      .withMessage("conveyorconfiguration.nbMinEmptyBinToAutoLaunchError")
      .on(this.entity);

    this.controller.addObject(this.entity);
  }

  private addExitStation(obj: { targetEntity }) {
    (obj.targetEntity as Conveyor.Model.ExitStation).number = null;
  }

  private async addAutoLaunchStation() {
    await this.autoLaunchStationService.createEntity({ conveyorConfigurationId: this.entity.id });
  }

  private async removeAutoLaunchStation(autoLaunchStation: Conveyor.Model.AutoLaunchStation) {
    await this.autoLaunchStationService.deleteEntities([autoLaunchStation], true);
  }

  private async addStationDescription() {
    await this.stationDescriptionService.createEntity({ conveyorConfigurationId: this.entity.id });
  }

  private async removeStationDescription(stationDescription: Conveyor.Model.StationDescription) {
    await this.stationDescriptionService.deleteEntities([stationDescription], true);
  }

  //#region Deactivation
  public detached(): void {
    this.controller.removeObject(this.entity);
    this.entity = null;
    this.disposables.forEach(d => d.dispose());
  }

  protected doNavigateBack(): void {
    // Unselect tree
    this.onCancel();
  }
  //#endregion Deactivation

  //#region Saving
  public async beforeSave(): Promise<void | boolean> {
    if (this.entity.autoLaunchStations.some(x => x.number == null)) {
      this.logError(this.i18n.tr('conveyorconfiguration.autoLaunchStationError'), null, true);
      return false;
    }
    if (this.entity.stationDescriptions.some(x => x.number == null || x.number < 1 || x.description == null || x.description.trim() == '')) {
      this.logError(this.i18n.tr('conveyorconfiguration.stationDescriptionError1'), null, true);
      return false;
    }
    const descriptions = this.entity.stationDescriptions.map(x => x.description);
    const uniqueDescriptions = new Set(descriptions);
    if (descriptions.length !== uniqueDescriptions.size) {
      this.logError(this.i18n.tr('conveyorconfiguration.stationDescriptionError2'), null, true);
      return false;
    }
    return true;
  }
  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean): Promise<any> {
    await super.save(silentSave, byPassLocking, navigateBack);
    if (this.onSaved) {
      await this.onSaved({ conveyor: this.entity });
    }
  }
  protected changeRouteAfterCreation(): void {
    // Do nothing
  }
  //#endregion Saving
}
