import { Router } from 'aurelia-router';
import { HttpClient } from 'aurelia-fetch-client';
import { Container, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Box, CustomLogger, FloatingBoxViewModel, GlobalLoaderService, ServiceBase } from "digiwall-lib";
import { UIInput } from "digiwall-lib/dist/typings/ui/forms/ui-input";
import { Zeus } from "generated";
import * as Constants from "../../../constants";
import * as ConvConstants from "../constants";
import { ConveyorUtils } from 'app-modules/conveyor/conveyor-utils';

export class LaunchInBinFloatingBox extends FloatingBoxViewModel {
  private binNumber: string;
  private scanBinInput: UIInput;
  private barcodeLength: number;
  private i18n: I18N;
  public isLaunchingMessage = false;

  constructor(private articleToActions: Array<Zeus.Web.Model.ArticleToAction>) {
    super('./launch-in-bin-floating-box.html', 'sm');
    if (articleToActions.length == 0) {
      this.dialogController.cancel();
    }
    this.i18n = Container.instance.get(I18N);
  }

  private async attached() {
    this.scanBinInput.element.addEventListener('enterpressed', () => this.binNumberScanned());

    let hardwareParams = await new ServiceBase<Zeus.Web.Model.HardwareParameters>(Constants.EntityTypeNames.HardwareParameters).firstEntity();
    this.barcodeLength = hardwareParams.binNumberMaxBarcodeLength ?? Number.MAX_SAFE_INTEGER;
  }

  @computedFrom('articleToActions.length', 'workOrder.id')
  private get workOrderName(): string {
    return this.articleToActions[0].actionWorkOrder.workOrderName ?? "" + this.articleToActions[0].actionWorkOrder.id
  }

  private focusInput(submit: boolean) {
    this.scanBinInput?.focus();
    if (submit) {
      this.binNumberScanned();
    }
  }

  private async binNumberScanned() {
    if (this.binNumber == null || this.binNumber.length == 0) {
      return;
    }

    if (await ConveyorUtils.canUseBinNumber(this.binNumber, this.barcodeLength, "toast")) {
      await this.launchMessage();
      this.dialogController.ok();
    } else {
      this.binNumber = null;
    }
  }

  private async launchMessage() {
    if (this.isLaunchingMessage) return;

    this.isLaunchingMessage = true;
    const globalLoaderService = Container.instance.get(GlobalLoaderService);
    globalLoaderService.show();
    try {
      const http = Container.instance.get(HttpClient);
      const dto: BinMessageToSendDto = {
        bin: this.binNumber,
        articleToActionIds: this.articleToActions.map(x => x.id)
      };
      let response = await http.post(ConvConstants.Application.SendMessage, JSON.stringify(dto));
      if (response.ok) {
        this.dialogController.ok();
      }
    } catch (e) {
      const logger = Container.instance.get(CustomLogger);
      logger.LogError(this.i18n.tr((e as Error).message), null, null, true);
    }
    this.isLaunchingMessage = false;
    globalLoaderService.hide();
  }
}

interface BinMessageToSendDto {
  bin: string;
  articleToActionIds: number[];
}
