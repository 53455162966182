import { Router } from 'aurelia-router';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { BindingEngine, autoinject, computedFrom, viewResources } from 'aurelia-framework';
import { Actions, CustomLogger, EntityDetailViewModelBase, ServiceBase, UITreePanelConfig, Various } from 'digiwall-lib';
import * as Constants from '../../../constants';
import * as ALConstants from '../constants';
import { Zeus } from 'generated';
import { AutoLaunch } from '../generated';
import { UnifiedStorageEntity } from 'storages/storage-tree-panel';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
export class AutoLaunchParameter extends EntityDetailViewModelBase<AutoLaunch.Model.AutoLaunchParameter> {
  public ressourceName: string = ALConstants.EntityTypeNames.AutoLaunchParameter;

  private treeList: UnifiedALStorageEntity[] = [];
  private treeConfig: UITreePanelConfig<UnifiedALStorageEntity>;
  private treeSelection = [];
  private loadingTree: boolean = false;
  private limitedToWarehouse: boolean = true;

  private treeNodeSelected: UnifiedALStorageEntity;
  private storageGroups: Array<Zeus.Web.Model.StorageGroup>;
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private isConveyorActive: boolean = false;

  constructor(router: Router, logger: CustomLogger, appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<AutoLaunch.Model.AutoLaunchParameter>(ALConstants.EntityTypeNames.AutoLaunchParameter));
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.treeConfig = new UITreePanelConfig<UnifiedALStorageEntity>({
      parentId: 'parentId',
      label: 'name',
      icon: 'icon',
      withLines: true,
      autoExpand: true,
      isNodeSelectable: node => this.isNodeSelectable({ node })
    });
    this.isConveyorActive = appModuleService.isActive(AppModuleEnum.Conveyor);
  }

  @computedFrom('treeNodeSelected', '_langWatcher')
  get documentTitle(): string {
    if (this.treeNodeSelected) {
      return this.i18n.tr("autolaunchparameter.autolaunchparameters") + " - " + this.i18n.tr(this.treeNodeSelected.typeTr) + " : " + this.treeNodeSelected.name;
    }
    return this.i18n.tr("autolaunchparameter.autolaunchparameters");
  }

  public async activate(params) {
    await super.activate(params);
    let predicate = null;
    if (this.limitedToWarehouse) {
      predicate = new Predicate("storageGroupTypeId", FilterQueryOp.LessThanOrEqual, Constants.StorageGroupTypes.Warehouse);
    }
    this.storageGroups = await this.storageGroupService.getEntities(predicate);
    this.treeList.push(...this.storageGroups.map(x => new UnifiedALStorageEntity(x)));
    let warehouses = this.treeList.filter(x => x.storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Warehouse);
    if (warehouses.length == 1) {
      await this.selectStorageGroup(warehouses[0]);
    }
    this.loadingTree = false;
  }

  async canDeactivate() {
    if (this.canUpdate) {
      return super.canDeactivate()
    }
    await this.cancel();
    return true;
  }

  private async selectStorageGroup(entry: UnifiedALStorageEntity) {
    this.treeNodeSelected = entry;
    this.treeSelection.push(entry);

    if (this.entity) {
      if (this.entity.entityAspect.entityState.isAdded()) {
        this.entity.entityAspect.setDetached();
      }
    }

    this.entity = await this.service.firstEntity(new Predicate("storageGroupId", FilterQueryOp.Equals, this.treeNodeSelected.storageGroup.id));
    if (this.entity == null) {
      this.entity = await this.service.createEntity({
        storageGroupId: this.treeNodeSelected.storageGroup.id,
        maxNbOrdersLaunched: 1,
      });
    }
  }

  //#region Tree panel
  private isNodeSelectable(args: { node: UnifiedALStorageEntity }): boolean {
    return !this.limitedToWarehouse || args.node.storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Warehouse;
  }

  private findTreeNode(nodeId: string) {
    return this.treeList.find(node => node.id == nodeId);
  }

  private async treeSelected(id: string) {
    let entry: UnifiedALStorageEntity = this.findTreeNode(id);
    if (entry == null) {
      return false;
    }
    await this.selectStorageGroup(entry);
  }
  //#endregion
}

class UnifiedALStorageEntity extends UnifiedStorageEntity {
  public typeTr: string;

  constructor(entity: Zeus.Web.Model.StorageGroup) {
    super(entity);
    this.id = entity.id.toString();
    this.parentId = entity.parentStorageGroupId?.toString();
    switch (entity.storageGroupTypeId) {
      case Constants.StorageGroupTypes.Site:
        this.typeTr = 'storage.siteName';
        break;
      case Constants.StorageGroupTypes.Warehouse:
        this.typeTr = 'storage.warehouseName';
        break;
      case Constants.StorageGroupTypes.ZoneGroup:
        this.typeTr = 'storage.zoneGroupName';
        break;
      default:
        this.typeTr = 'storage.zoneName';
        break;
    }
  }
}

enum StorageTreePanelTypeId {
  StorageGroup = "StorageGroup"
}
