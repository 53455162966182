export class Endpoints {
  public static StaticRackLocation = {
    ValueIsInRange: "/api/MobileScreens/StaticRackLocation/ValueIsInRange",
    SequenceIsUnique: "/api/MobileScreens/StaticRackLocation/SequenceIsUnique",
    GetLocationLevelForStaticDto: "/api/MobileScreens/StaticRackLocation/GetLocationLevelForStaticDto",
  }
  public static Replenishment = {
    CreateWorkOrders: "/api/MobileScreens/Replenishment/CreateAndLaunchWorkOrders"
  }
}
