import { Container } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Box } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import * as toastr from 'toastr';
import { Application } from './constants';

export class ConveyorUtils {
  public static async canUseBinNumber(barcode: string, barcodeLength: number, displayMode: 'toast' | 'box') {
    var httpClient = Container.instance.get(HttpClient);
    var i18n = Container.instance.get(I18N);
    var box = Container.instance.get(Box);

    let result = await httpClient.get(Application.IsBinAlreadyInUse + `?binNumber=${barcode}`);
    if (result.ok) {
      return true;
    }

    if (result.status == 400) {
      let errorMsg = i18n.tr('launchinbin.barcodeLengthError', { length: barcodeLength });

      if (displayMode == "toast") {
        toastr.error(errorMsg);
      } else {
        box.showError(errorMsg, i18n.tr('general.error'), null, false);
      }

      return false;
    }

    if (result.status == 409) {
      const workOrderName = await result.text();
      let errorMsg = i18n.tr("launchinbin.binInUse");
      if ((workOrderName ?? "").length > 0) {
        errorMsg = i18n.tr("launchinbin.binInUseForWorkOrder", { workOrderName });
      }
      if (displayMode == "toast") {
        toastr.error(errorMsg);
      } else {
        box.showError(errorMsg, i18n.tr('general.error'), null, false);
      }
    }
    return false;
  }
}
