import { Router } from 'aurelia-router';
import { ViewModelBase, CustomLogger, DialogBoxViewModel, GlobalLoaderService } from 'digiwall-lib';
import { autoinject } from "aurelia-framework";
import * as toastr from 'toastr';
import { HttpClient } from 'aurelia-fetch-client';
import { Endpoints } from 'endpoints';

@autoinject()
export class ResetData extends ViewModelBase {
  get ribbonHeaderText(): string {
    throw new Error('Method not implemented.');
  }
  get ressourceName(): string {
    throw new Error('Method not implemented.');
  }

  constructor(router: Router, logger: CustomLogger, private http: HttpClient, private globalLoaderService: GlobalLoaderService) {
    super(router, logger);
  }

  public isBusy = false;
  public allSelected = false;
  public model = new ResetDataDTO();

  public selectAll() {
    this.model.articleFamilies = true;
    this.model.articles = true;
    this.model.storageGroups = true;
    this.model.locationFormats = true;
    this.model.trayTemplates = true;
  }

  public async resetAppData(): Promise<void> {
    if (this.isBusy) return;
    await this.box.showQuestion('Are you sure you want to delete this data?', 'Reset data', [
      { label: this.i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
      { label: this.i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
    ])
      .whenClosed(async (result) => {
        if (!result.wasCancelled) {
          this.globalLoaderService.show();
          this.isBusy = true;
          if (this.model != null) {
            let response = await this.http.post(Endpoints.Utils.ResetAppData, this.model.stringify());
            if (response.ok) {
              toastr.info("Reset done with success.");
            }
          }
          this.isBusy = false;
          this.globalLoaderService.hide();
        }
      });
  }
}

export class ResetDataDTO {

  public stringify() {
    return JSON.stringify({
      WorkOrders: this.workOrders,
      WorkOrderArticlesToAction: this.workOrderArticlesToAction,
      ActionHistories: this.actionHistories,
      WorkOrderBatchProcesses: this.workOrderBatchProcesses,
      LocationForActions: this.locationForActions,
      WorkOrderProcesses: this.workOrderProcesses,
      ArticleFamilies: this.articleFamilies,
      Articles: this.articles,
      ArticleLocations: this.articleLocations,
      StorageGroups: this.storageGroups,
      Storages: this.storages,
      Conveyors: this.conveyors,
      Workstations: this.workstations,
      Locations: this.locations,
      LocationFormats: this.locationFormats,
      TrayTemplates: this.trayTemplates
    });
  }

  //#region work order
  _workOrders = false;
  public get workOrders(): boolean { return this._workOrders };
  public set workOrders(val) {
    this._workOrders = val;
    if (val) {
      this.workOrderProcesses = true;
      this.workOrderBatchProcesses = true;
      this.workOrderArticlesToAction = true;
    }
  }

  _workOrderArticlesToAction = false;
  public get workOrderArticlesToAction(): boolean { return this._workOrderArticlesToAction }
  public set workOrderArticlesToAction(val) {
    this._workOrderArticlesToAction = val;
    if (val) {
      this.workOrderBatchProcesses = true;
      this.actionHistories = true;
    }
  }

  _actionHistories = false;
  public get actionHistories(): boolean { return this._actionHistories }
  public set actionHistories(val) {
    this._actionHistories = val;
  }

  _workOrderBatchProcesses = false;
  public get workOrderBatchProcesses(): boolean { return this._workOrderBatchProcesses };
  public set workOrderBatchProcesses(val) {
    this._workOrderBatchProcesses = val;
    if (val)
      this.workOrderProcesses = true;
    this.locationForActions = true;
  };

  _locationForActions = false;
  public get locationForActions(): boolean { return this._locationForActions };
  public set locationForActions(val) {
    this._locationForActions = val;
    if (val)
      this.workOrderProcesses = true;
  };

  _workOrderProcesses = false;
  public get workOrderProcesses(): boolean { return this._workOrderProcesses };
  public set workOrderProcesses(val) {
    this._workOrderProcesses = val;
  };
  //#endregion

  //#region Article
  _articleFamilies = false;
  public get articleFamilies(): boolean { return this._articleFamilies };
  public set articleFamilies(val) {
    this._articleFamilies = val;
  };

  _articles = false;
  public get articles(): boolean { return this._articles };
  public set articles(val) {
    this._articles = val;
    if (val) {
      this.articleLocations = true;
      this.workOrderArticlesToAction = true;
    }
  };

  _articleLocations = false;
  public get articleLocations(): boolean { return this._articleLocations };
  public set articleLocations(val) {
    this._articleLocations = val;
    if (val) {
      this.locationForActions = true;
    }
  };
  //#endregion

  //#region storage
  _storageGroups = false;
  public get storageGroups(): boolean { return this._storageGroups };
  public set storageGroups(val) {
    this._storageGroups = val;
    if (val)
      this.storages = true;
    this.conveyors = true;
  };

  _storages = false;
  public get storages(): boolean { return this._storages }
  public set storages(val) {
    this._storages = val;
    if (val) {
      this.locations = true;
    }
  }

  _conveyors = false;
  public get conveyors(): boolean { return this._conveyors }
  public set conveyors(val) {
    this._conveyors = val;
    if (val) {
      this._workstations = true;
    }
  }

  _workstations = false;
  public get workstations(): boolean { return this._workstations }
  public set workstations(val) {
    this._workstations = val;
  }

  _locations = false;
  public get locations(): boolean { return this._locations }
  public set locations(val) {
    this._locations = val;
    if (val) {
      this.workOrders = true;
      this.articleLocations = true;
    }

  }

  _locationFormats = false;
  public get locationFormats(): boolean { return this._locationFormats };
  public set locationFormats(val) {
    this._locationFormats = val;
  };

  _trayTemplates = false;
  public get trayTemplates(): boolean { return this._trayTemplates };
  public set trayTemplates(val) {
    this._trayTemplates = val;
    if (val)
      this.locations = true;
  };
  //#endregion
}
