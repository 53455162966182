import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { Endpoints } from '../endpoints';
import { MobileScreens } from '../generated';

@autoinject()
export class ReplenishmentApiService {
  constructor(private httpClient: HttpClient) { }

  public async createWorkOrders(body: number[]): Promise<MobileScreens.Model.ReplenishmentWorkOrderResultDto> {
    try {
      let response = await this.httpClient.post(Endpoints.Replenishment.CreateWorkOrders, JSON.stringify(body))
      if (response?.ok) {
        return await response.json();
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
