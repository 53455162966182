import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { MobileScreens } from "../generated";
import * as Constants from '../constants';
import { ColDef } from 'ag-grid-community';
import { ReplenishmentApiService } from '../api-services/replenishment-api-service';

@autoinject
export class ReplenishmentNeeds extends ListViewModelBase<MobileScreens.Model.ReplenishmentNeed> {
  public ressourceName: string = Constants.EntityTypeNames.ReplenishmentNeed;
  public queryInProgress: boolean = false;

  constructor(router: Router, logger: CustomLogger, private replenismentApiService: ReplenishmentApiService) {
    super(router, logger, new ServiceBase<MobileScreens.Model.ReplenishmentNeed>(Constants.EntityTypeNames.ReplenishmentNeed));
    this.service.gridDataSource.expands = ['article', 'baseUom'];
  }

  private async createOrders() {
    this.queryInProgress = true;
    let workOrderCountResult = await this.replenismentApiService.createWorkOrders(
      this.selectedEntities.map(x => x.articleId)
    );
    if (workOrderCountResult.totalWorkOrders != null && workOrderCountResult.totalWorkOrders > 0) {
      this.logger.Log(this.i18n.tr('replenishment.workOrdersCreated', { data: workOrderCountResult.totalWorkOrders }), null, null, true);
    } else {
      this.logger.Log(this.i18n.tr('replenishment.noWorkOrdersCreated'), null, null, true);
    }
    if (workOrderCountResult.failedWorkOrders != null && workOrderCountResult.failedWorkOrders > 0) {
      this.logger.LogError(this.i18n.tr('replenishment.someWoFailedToLaunch', { data: workOrderCountResult.totalWorkOrders }), null, null, true);
    }
    this.queryInProgress = false;
    this.recalculate();
  }

  @computedFrom('queryInProgress', 'selectedEntities.length')
  get disableCreateWoButton(): boolean {
    return this.queryInProgress || this.selectedEntities.length == 0;
  }

  private recalculate() {
    this.gridOptions.api.setDatasource(this.service.gridDataSource);
  }

  private getCellValue(entity: MobileScreens.Model.ReplenishmentNeed, ...parameters: string[]) {
    if (entity == null) {
      return "";
    }
    let obj: any = entity;
    for (let param of parameters) {
      obj = obj[param]
    }
    return obj + " " + entity.baseUom.denomination._translation;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this, {}) as ColDef,
      {
        headerName: this.i18n.tr("article.reference"),
        field: "article.reference",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("article.description"),
        field: "article.description._translation",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("replenishment.pickLocationQuantity"),
        field: "pickLocationQuantity",
        type: FieldType.Number,
        valueGetter: (params) => this.getCellValue(params.data, "pickLocationQuantity"),
        cellRenderer: (params) => this.getCellValue(params.data, "pickLocationQuantity"),
      },
      {
        headerName: this.i18n.tr("article.minimalPiecePickStock"),
        field: "article.minimalPiecePickStock",
        type: FieldType.Number,
        valueGetter: (params) => this.getCellValue(params.data, "article", "minimalPiecePickStock"),
        cellRenderer: (params) => this.getCellValue(params.data, "article", "minimalPiecePickStock"),
      },
      {
        headerName: this.i18n.tr("article.maximumPiecePickStock"),
        field: "article.maximumPiecePickStock",
        type: FieldType.Number,
        valueGetter: (params) => this.getCellValue(params.data, "article", "maximumPiecePickStock"),
        cellRenderer: (params) => this.getCellValue(params.data, "article", "maximumPiecePickStock"),
      },
      {
        headerName: this.i18n.tr("replenishment.pickOrderQuantity"),
        field: "pickOrderQuantity",
        type: FieldType.Number,
        valueGetter: (params) => this.getCellValue(params.data, "pickOrderQuantity"),
        cellRenderer: (params) => this.getCellValue(params.data, "pickOrderQuantity"),
      },
      {
        headerName: this.i18n.tr("replenishment.percentageBelowMin"),
        field: "percentageBelowMin",
        type: FieldType.Number,
        sort: 'desc',
      },
    ];
    return defs;
  }
}
